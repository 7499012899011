import {
  Box,
  BoxProps,
  ChakraProps,
  colors,
  Container,
  Heading,
  HideWithCSS,
  Stack,
  VStack,
} from "@biblioteksentralen/react";
import { SeasonalBanners } from "@forrigebok/generatedtypes";
import { getImageDimensions } from "@sanity/asset-utils";
import { jsonFetcher } from "common";
import NextImage from "next/image";
import { Teller } from "src/components/CountDown";
import { BannerResponse } from "src/pages/api/internal/banner.api";
import { imageUrlBuilder } from "src/utils/sanity";
import { useTranslation } from "src/utils/useTranslation";
import useSWR from "swr";
import { SWRStatus } from "../../SWRStatus";
import { useBannerConfig } from "../Banner";
import { VerkListe } from "../VerkListe";

export function SommerlesBanner({ ...chakraProps }: ChakraProps) {
  const { t } = useTranslation();
  const bannerResponse = useSWR<BannerResponse>(
    "/api/internal/banner?audienceAges=6-8 år,9-10 år,11-12 år",
    jsonFetcher
  );

  const bannerConfigResponse = useBannerConfig();

  const graphics = bannerConfigResponse.data?.sommerlesGraphics;

  return (
    <Box backgroundColor={colors.alertYellow} paddingY="2rem" minH="25rem" marginY="2rem" {...chakraProps}>
      <Container maxW="container.lg" position="relative" display="flex" flexDirection="column">
        <HideWithCSS
          marginX="-.75rem" // "-.75rem" to remove padding of Container for easier placement of images (for example, translateX(100%) will place the image exactly at the edge of the container)
          below="xl"
          pointerEvents="none"
        >
          <GrafikkElement transform="translate(-100%, -35%)" image={graphics?.[0]} />
          <GrafikkElement transform="translate(110%, -25%)" right={0} image={graphics?.[1]} />
          <GrafikkElement transform="translate(-110%, 40%)" bottom={0} image={graphics?.[2]} />
          <GrafikkElement transform="translate(100%, 15%)" bottom={0} right={0} image={graphics?.[3]} />
        </HideWithCSS>
        <Stack spacing="2rem">
          <Heading textAlign="center">{t("Sommerles er i gang. Husk å registrere barnebøker!")}</Heading>
          {!bannerResponse.data ? (
            <SWRStatus centerSpinner swrResponse={bannerResponse} />
          ) : (
            <>
              <VStack marginBottom="3rem">
                <Box>{t("Så langt har vi")}</Box>
                <Teller count={bannerResponse.data.totalt} skiveFarge="white" fontSize="1.75rem" />
                <Box>{t("registrerte Sommerles-bøker")}</Box>
              </VStack>
              <VerkListe tittel={t("Siste Sommerles-bøker")} verk={bannerResponse.data.verk} />
            </>
          )}
        </Stack>
      </Container>
    </Box>
  );
}

type SanityImage = NonNullable<SeasonalBanners["sommerlesGraphics"]>[number];

const GrafikkElement = ({ image, ...chakraProps }: { image?: SanityImage } & BoxProps) => {
  if (!image) return null;

  const width = 400;
  const height = Math.round(width / getImageDimensions(image).aspectRatio);
  const src = imageUrlBuilder(image)?.width(width).format("webp").quality(80).url() ?? "";

  return (
    <Box width="12em" height="auto" position="absolute" {...chakraProps}>
      <NextImage src={src} alt={""} width={width} height={height} />
    </Box>
  );
};
