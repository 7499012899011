
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Alert, Link, VisuallyHidden } from "@biblioteksentralen/react";
import { ChakraProps, Container, Grid, Text } from "@chakra-ui/react";
import NextLink from "next/link";
import { useTranslation } from "src/utils/useTranslation";
import CountDown from "../components/CountDown";
import { Banner } from "../components/forside/Banner";
import ForsideNyhet from "../components/forside/ForsideNyhet";
import KomIgang from "../components/forside/KomIgang";
import { SisteRegistreringer } from "../components/forside/SisteRegistreringer";
import Stats from "../components/forside/Stats";
import { VerkSomTrengerFlereRegistreringer } from "../components/forside/VerkSomTrengerFlereRegistreringer";
import { useSanityStats } from "../components/forside/useSanityStats";
import { useUser } from "../utils/useUser";
function Index() {
    const user = useUser();
    const { t } = useTranslation();
    return (<Grid gap=".75rem">
      <VisuallyHidden>
        <h1>{t("Forside - Forrigebok")}</h1>
      </VisuallyHidden>
      <Container maxW="container.lg">
        <Text fontWeight={600} fontSize={{ base: "xl", md: "3xl" }}>
          {t("Hei, {email}", { email: user.user?.email })}
        </Text>
        <UferdigeRegisteringerAlert marginTop=".5rem"/>
      </Container>
      <CountDown />
      <Banner />
      <KomIgang />
      <ForsideNyhet />
      <Stats />
      <Container maxW="container.lg">
        <SisteRegistreringer />
      </Container>
      <VerkSomTrengerFlereRegistreringer marginTop="2rem"/>
    </Grid>);
}
function UferdigeRegisteringerAlert({ ...chakraProps }: ChakraProps) {
    const stats = useSanityStats();
    const uferdigeRegisteringerCount = stats.data?.brukersRegistreringer.upubliserteRegistreringerCount ?? 0;
    const { t } = useTranslation();
    if (uferdigeRegisteringerCount === 0)
        return null;
    return (<Alert status="warning" {...chakraProps}>
      <Text>
        {t("Du har {count} påbegynt registrering. Ønsker du å fullføre den?", { count: uferdigeRegisteringerCount })}
      </Text>
      <Link as={NextLink} href="/mine-registreringer">
        {t("Gå til Mine registreringer")}
      </Link>
    </Alert>);
}
export default Index;

    async function __Next_Translate__getStaticProps__1916ffece08__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1916ffece08__ as getStaticProps }
  