import { FiChevronLeft } from "@biblioteksentralen/icons";
import { Icon, Link, LinkProps } from "@biblioteksentralen/react";
import { forwardRef, ReactNode } from "react";

type Props = {
  children: ReactNode;
} & LinkProps;

// eslint-disable-next-line react/display-name
export const ChevronLink = forwardRef(({ children, ...chakraProps }: Props, ref) => (
  <Link variant="plain" {...chakraProps} display="flex" gap=".5em" alignItems="center">
    <Icon as={FiChevronLeft} />
    {children}
  </Link>
));
